import { call, put, takeEvery, all, fork, select } from 'redux-saga/effects';
import { api } from '../../helpers/api';
import { listDirectoryRequest, listDirectorySuccess } from './actions';
import { refreshRequest } from '../auth/actions';
import { createToastRequest } from '../toasts/actions';
import { Directory } from './constants';
import history from '../../history';

const getAuth = (state) => state.auth?.auth;

export function* fetchDirectory(ops) {
  const { data, callback } = ops;
  const { projectId } = data || {};
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/directory?projectId=${projectId}`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(
          listDirectorySuccess({
            ...parsed,
            JWTAlgorithm: [
              'HS256',
              'HS384',
              'HS512',
              'RS256',
              'RS384',
              'RS512',
              'ES256',
              'ES384',
              'ES512',
              'PS256',
              'PS384',
              'PS512',
            ],
          }),
        );
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(listDirectoryRequest, data, callback));
        if (callback) callback(true);
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
        if (callback) callback(true);
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

function* directorySaga() {
  yield takeEvery(Directory.listDirectoryRequest, fetchDirectory);
}

function* configSaga() {
  yield all([fork(directorySaga)]);
}

export default configSaga;
