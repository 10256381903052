/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import ProjectDefault from '../../pictures/project-default.svg';
import ActionText from '../../components/ActionText/ActionText';
import { registerRequest } from '../../store/auth/actions';
import Input from '../../components/Input/Input';

import * as styles from './Auth.module.scss';

const Schema = Yup.object().shape({
  first_name: Yup.string().required('Name required'),
  last_name: Yup.string().required('Last Name required'),
  email: Yup.string().email('Invalid email. Please enter a valid one.').required('Email required'),
  password: Yup.string().required('Password required').matches(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_+=();:.,\/><-^])[A-Za-z\d@$!%*#?&_+=();:.,\/><-^]{8,}$/,
    'Use 8 or more chars with a mix of letters, digits & symbols',
  ),
  confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirm password required'),
});

function Register({ register }) {
  const navigate = useNavigate();
  const {
    handleSubmit, handleChange, values, errors, touched, isSubmitting,
  } = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      confirm_password: '',
    },
    validationSchema: Schema,
    onSubmit: (v, { setSubmitting }) => {
      register(
        {
          first_name: v.first_name,
          last_name: v.last_name,
          email: v.email,
          password: v.password,
        },
        (status) => {
          if (status) {
            navigate('/')
          } else {
            setSubmitting(false)
          }
        },
      );
    },
  });

  const goToLogin = () => {
    navigate('/login');
  };

  const activeSubmit = values.first_name !== '' && values.last_name !== '' && values.email !== ''
     && values.password !== '' && values.confirm_password !== '';

  return (
    <div className={styles.wrapper}>
      <div className={styles.box}>
        <div className={styles.logoBox}>
          <ProjectDefault />
          <h2>Trigger API Gateway</h2>
        </div>
        <div className={styles.titleBox}>
          <h3>Register</h3>
          <div className={styles.actionButton}><ActionText title="Login" onClick={goToLogin} /></div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className={styles.row}>
            <input
              className={cn({ [styles.errorInput]: errors.first_name && touched.first_name })}
              autoComplete="username"
              type="text"
              name="first_name"
              id="first_name"
              placeholder="First name"
              onChange={handleChange}
              value={values.first_name}
            />
            {errors.first_name && touched.first_name && (
              <span className={styles.errorMessage}>{errors.first_name}</span>
            )}
          </div>

          <div className={styles.row}>
            <input
              className={cn({ [styles.errorInput]: errors.last_name && touched.last_name })}
              type="text"
              name="last_name"
              id="last_name"
              placeholder="Last name"
              onChange={handleChange}
              value={values.last_name}
            />
            {errors.last_name && touched.last_name && (
              <span className={styles.errorMessage}>{errors.last_name}</span>
            )}
          </div>

          <div className={styles.row}>
            <input
              className={cn({ [styles.errorInput]: errors.email && touched.email })}
              autoComplete="email"
              type="text"
              name="email"
              id="email"
              placeholder="Email"
              onChange={handleChange}
              value={values.email}
            />
            {errors.email && touched.email && (
              <span className={styles.errorMessage}>{errors.email}</span>
            )}
          </div>

          <div className={styles.row}>
            <Input
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              isPassword
              realPassword
              handleChange={handleChange}
              value={values.password}
              autoComplete="new-password"
              error={errors.password && touched.password}
            />
            {errors.password && touched.password && (
              <span className={styles.errorMessage}>{errors.password}</span>
            )}
          </div>

          <div className={styles.row}>
            <Input
              type="password"
              name="confirm_password"
              id="confirm_password"
              placeholder="Confirm password"
              isPassword
              realPassword
              handleChange={handleChange}
              value={values.confirm_password}
              error={errors.confirm_password && touched.confirm_password}
            />
            {errors.confirm_password && touched.confirm_password && (
              <span className={styles.errorMessage}>{errors.confirm_password}</span>
            )}
          </div>

          <div className={styles.row}>
            <button
              type="submit"
              disabled={!(isSubmitting || activeSubmit)}
            >
              Register
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  register: (data, callback) => dispatch(registerRequest(data, callback)),
});

export default connect(null, mapDispatchToProps)(Register);
