import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import ProjectDefault from '../../pictures/project-default.svg';
import ActionText from '../../components/ActionText/ActionText';
import Input from '../../components/Input/Input';
import { authRequest } from '../../store/auth/actions';

import * as styles from './Auth.module.scss';

const Schema = Yup.object().shape({
  username: Yup.string().email('Invalid email. Please enter a valid one.').required('Email required'),
  password: Yup.string().required('Password required'),
});

function Login({ auth }) {
  const navigate = useNavigate();
  const {
    handleSubmit, handleChange, values, errors, touched, isSubmitting,
  } = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Schema,
    onSubmit: (v, { setSubmitting }) => {
      auth(
        { ...v },
        (status) => {
          if (status) navigate('/');
          if (!status) setSubmitting(false);
        },
      );
    },
  });

  const goToRegister = () => {
    navigate('/register');
  };

  const goToResetPassword = () => {
    navigate('/reset-password');
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.box}>
        <div className={styles.logoBox}>
          <ProjectDefault />
          <h2>Trigger API Gateway</h2>
        </div>
        <div className={styles.titleBox}>
          <h3>Login</h3>
          <div className={styles.actionButton}><ActionText title="Register" onClick={goToRegister} /></div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className={styles.row}>
            <input
              className={cn({ [styles.errorInput]: errors.username && touched.username })}
              autoComplete="email"
              type="text"
              name="username"
              id="username"
              placeholder="Email"
              onChange={handleChange}
              value={values.username}
            />
            {errors.username && touched.username && (
              <span className={styles.errorMessage}>{errors.username}</span>
            )}
          </div>

          <div className={styles.row}>
            <Input
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              isPassword
              realPassword
              handleChange={handleChange}
              value={values.password}
              autoComplete="current-password"
              error={errors.password && touched.password}
            />
            {errors.password && touched.password && (
              <span className={styles.errorMessage}>{errors.password}</span>
            )}
          </div>

          <div className={styles.row}>
            <button
              type="submit"
              className={cn({ [styles.buttonSubmitting]: isSubmitting })}
            >
              Login
            </button>
          </div>
        </form>
        <div className={styles.footerBox}>
          <ActionText title="Reset password" onClick={goToResetPassword} />
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  auth: (data, callback) => dispatch(authRequest(data, callback)),
});

export default connect(null, mapDispatchToProps)(Login);
