@use "sass:map";
@import '../../styles/variables.scss';

.wrapper {
    width: 100%;
    height: 100vh;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
}

.box {
    margin-top: 120px;
    margin-bottom: 24px;
    gap: 12px;
    display: flex;
    justify-content: start;
    flex-direction: column;
    max-width: 290px;

    .logoBox {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;
        margin-top: 12px;
        margin-bottom: 12px;

        h2 {
            font-family: 'Avenir', sans-serif;
            font-weight: 800;
            font-size: 24px;
            color: #000;
        }
    }

    .titleBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        position: relative;

        .actionButton {
            position: relative;
            top: 2px;
        }

        h3 {
            font-family: 'Open Sans', sans-serif;
            font-weight: 400;
            font-size: 28px;
        }
    }

    .successBox {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 12px;
    }

    .footerBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    h3 {
        font-size: map.get($font-sizes, "h4");
        font-weight: map.get($font-weights, "bold");
    }

    form {
        display: flex;
        flex-direction: column;
        width: 280px;
    }

    .row {
        position: relative;
        padding: 12px 0;
        max-width: 100%;
        width: 100%;

        label {
            position: absolute;
            top: -4px;
            left: 0;
            font-size: map.get($font-sizes, "body");
            position: relative;

            .required {
                color: map.get($colors-basic, "primaryBlue");
                position: absolute;
                top: 2px;
                right: -12px;
            }
        }

        input, button {
            width: 100%;
        }

        input {
            box-sizing: border-box;
            color: map.get($colors-basic, "textBlack");
            border: none;
            outline: none;
            font-size: map.get($font-sizes, "body");
            background-color: #F8F8F9;
            height: 36px;
            // padding-right: 36px;
            padding-right: 12px;
            padding-left: 12px;
            border: 1px solid #F2F2F2;
            border-radius: 4px;

            &::placeholder {
                color: map.get($colors-basic, "textGray")
            }

            &:focus {
                background-color: white;
                border: 1px solid map.get($colors-basic, "primaryBlue");
            }

            &.errorInput {
                border: 1px solid map.get($colors-red, 100);
                background-color: #FEF4F4;
            }
        }

        .errorMessage {
            overflow: visible;
            white-space: nowrap;
            color: map.get($colors-red, 100);
            position: absolute;
            font-size: 10px;
            bottom: -4px;
            left: 8px;
        }

        button {
            cursor: pointer;
            height: 36px;
            border: none;
            border-radius: 4px;
            box-sizing: border-box;
            background-color: map.get($colors-basic, "primaryBlue");
            font-weight: map.get($font-weights, "semibold");
            color: #fff;

            &[disabled] {
                background-color: map.get($colors-basic, "secondary");
                color: map.get($colors-basic, "textDisabled");
                cursor: initial;
            }

            &.buttonSubmitting {
                opacity: 0.6;
            }
        }
    }


    .message {
        margin-top: 12px;
        font-size: map.get($font-sizes, "subtitle");

        a {
            font-weight: map.get($font-weights, "semibold");
            text-decoration: underline;
            color: map.get($colors-basic, "primaryBlue");
        }
    }
}